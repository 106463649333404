<template>

  <div class="">

    <div class="highlights padding-sides-md row">

      <router-link
          to="/"
          class="highlight-text col-3 bold "
          v-bind:class="getPageActiveClass('home')"
          style="text-align: center; padding: 1em 0;">
        Highlights
      </router-link>

      <router-link
          to="/today"
          class="highlight-text col-3 bold"
          v-bind:class="getPageActiveClass('today')"
          style="text-align: center; padding: 1em 0;">
        Today
      </router-link>

      <router-link
          to="/upcoming"
          class="highlight-text col-3 bold"
          v-bind:class="getPageActiveClass('upcoming')"
          style="text-align: center; padding: 1em 0;">
        Upcoming
      </router-link>

      <router-link
          to="/countries"
          class="highlight-text col-3 bold"
          v-bind:class="getPageActiveClass('countries')"
          style="text-align: center; padding: 1em 0;">
        Countries
      </router-link>

    </div>

  </div>
</template>


<script>

export default {
  name: 'Highlights',
  data: function (){

    return {
      sub_page: '',
      child_page : '',
      dates: []
    }

  },
  methods: {
    setSubPage: function (page){

      var default_payload = this.$store.state.default_games_filter;
      var games_filter = this.$store.state.games_filter;

      if(page === "upcoming" ) {

        this.child_page = '';
        this.sub_page = page;
        this.$store.dispatch("setCurrentSubPage",page);

        if(games_filter.upcoming !== 1 ) {

          default_payload.upcoming = 1;
          default_payload.highlights = 0;
          default_payload.date = "";
          this.$store.dispatch("setGamesFilter",default_payload);

        }

      }
      else {

        this.$store.dispatch("setCurrentSubPage","upcoming");
        this.sub_page = "upcoming";

        if(games_filter.date !== page ) {

          this.child_page = page;

          default_payload.date = page
          default_payload.highlights = 0;
          default_payload.upcoming = 0;
          this.$store.dispatch("setGamesFilter", default_payload);

        }

      }

    },
    getSubPageActiveClass: function (subpage) {

      return subpage === this.$store.state.current_sub_page ? 'menu-highlighted' : "";

    },
    getPageActiveClass: function (page) {

      return page === this.$store.state.current_page ? 'menu-highlighted' : "";

    },
    geChildPageActiveClass: function (childpage) {

      return childpage === this.$store.state.upcoming_date ? 'selected-date' : "";

    },

  },
  mounted: function () {


  },
  computed: {

    current_page: function () {

      return this.$store.state.current_page;
    }
  }

}
</script>

<style scoped>

.custom-divider {
  border-top: 2px solid var(--primary);
  border-bottom: 2px solid var(--primary);
}

</style>